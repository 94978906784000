<template>
  <div class="addProductPageBox page-info-content">
    <!-- 面包屑 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-position="left"
      label-width="120px"
      class="ruleForm form-item-row"
    >
      <h3 class="page-subtitle-shade">
        基本信息
      </h3>
      <el-form-item label="资金方" :rules="[...rules.selectiveType]" prop="funderId">
        <div class="flexBox">
          <el-tooltip class="item" effect="dark" content="找不到资金方，去添加" placement="top">
            <i class="el-icon-question iconLeft" />
          </el-tooltip>
          <el-select
            v-model="ruleForm.funderId"
            size="small"
            placeholder="请选择"
            filterable
            :disabled="!!ruleForm.productNo"
            reserve-keyword
            :remote-method="remoteFunder"
            @change="changeFunder"
          >
            <el-option
              v-for="item in funderArr"
              :key="item.id"
              :label="item.funderName"
              :value="item.id"
            />
          </el-select>
          <router-link class="iconRight" :to="{path:'/memberManagement/managementCompany'}">
            <i class="el-icon-circle-plus-outline" />
          </router-link>
        </div>
      </el-form-item>
      <el-form-item label="融资方案来源" :rules="[...rules.selectiveType]" prop="source">
        <el-radio-group v-model="ruleForm.source" @change="changeSource">
          <el-radio :label="1">
            后台录入
          </el-radio>
          <el-radio :label="2">
            资方确定
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <h3 v-if="ruleForm.source===1" class="page-subtitle-shade">
        融资方案配置
      </h3>
      <el-form-item
        v-if="ruleForm.source===1"
        label="融资比例"
        :rules="[...rules.message]"
        prop="loanRate"
      >
        <div class="flexBox">
          <el-input-number
            v-model="ruleForm.loanRate"
            :precision="4"
            :controls="false"
            size="small"
            placeholder="请输入"
            :min="0"
            :max="100"
          />
          <span class="positionBox">%</span>
        </div>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="还款方式"
        prop="repayType"
      >
        <el-select v-model="ruleForm.repayType" size="small" placeholder="请选择">
          <el-option label="等额本息" :value="1" />
          <el-option label="到期一次性还本付息" :value="2" />
          <el-option label="按月结息到期还本" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="结息方式"
        prop="interestPayType"
      >
        <el-select v-model="ruleForm.interestPayType" size="small" placeholder="请选择">
          <el-option label="定日结息" :value="1" />
          <el-option label="贷放日结息" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="支付方式"
        prop="paymentType"
      >
        <el-select v-model="ruleForm.paymentType" size="small" placeholder="请选择">
          <el-option label="自主支付" :value="0" />
          <el-option label="定向支付" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        :rules="[...rules.message]"
        label="贷款周期"
        prop="maxLoanCycle"
      >
        <div class="flexBox">
          <el-input-number
            v-model="ruleForm.maxLoanCycle"
            :precision="0"
            :controls="false"
            size="small"
            type="number"
            placeholder="请输入"
            :min="0"
          />
          <el-select
            v-model="ruleForm.maxLoanCycleUnit"
            style="width:100px"
            size="small"
            placeholder="请选择"
            :value="1"
          >
            <el-option label="天" :value="1" />
            <el-option label="月" :value="2" />
            <el-option label="年" :value="3" />
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        label="贷款展期期限"
        :rules="[...rules.message]"
        prop="loanExtensionPeriod"
      >
        <div class="flexBox">
          <el-input-number
            v-model="ruleForm.loanExtensionPeriod"
            size="small"
            type="number"
            :precision="0"
            :controls="false"
            placeholder="请输入"
            :min="0"
          />
          <span class="positionBox">天</span>
        </div>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.source===1"
        label="是否需要担保"
        :rules="[...rules.selectiveType]"
        class="overallWidth"
        prop="needGuarantee"
      >
        <el-radio-group v-model="ruleForm.needGuarantee" @change="changeNeedGuarantee">
          <el-radio :label="2">
            有担保
          </el-radio>
          <el-radio :label="1">
            无担保
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.needGuarantee===2&&ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="担保方式"
        prop="guaranteeType"
      >
        <el-select v-model="ruleForm.guaranteeType" size="small" placeholder="请选择">
          <el-option label="信用担保" :value="1" />
          <el-option label="抵押担保" :value="2" />
          <el-option label="质押担保" :value="3" />
          <el-option label="保证担保" :value="4" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.needGuarantee===2&&ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="担保公司"
        prop="guaranteeId"
      >
        <div class="flexBox">
          <el-tooltip class="item" effect="dark" content="找不到担保公司，去添加" placement="top">
            <i class="el-icon-question iconLeft" />
          </el-tooltip>
          <el-select
            v-model="ruleForm.guaranteeId"
            size="small"
            placeholder="请选择"
            filterable
            reserve-keyword
            :remote-method="remoteGuarantee"
            @change="changeGuarantee"
          >
            <el-option
              v-for="item in guaranteeArr"
              :key="item.id"
              :label="item.guaranteeCmpName"
              :value="item.id"
            />
          </el-select>
          <router-link class="iconRight" :to="{path:'/memberManagement/guaranteeCompany'}">
            <i class="el-icon-circle-plus-outline" />
          </router-link>
        </div>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.needGuarantee===2&&ruleForm.source===1"
        class="allWidth"
        :rules="[...rules.selectiveType]"
        label="担保协议"
        prop="needSecurityAgreement"
      >
        <div class="flexBox">
          <el-radio-group v-model="ruleForm.needSecurityAgreement" @change="verifyData">
            <el-radio :label="1">
              担保方需在线签署
            </el-radio>
            <el-radio :label="2">
              无需在线签署
            </el-radio>
          </el-radio-group>
          <i v-if="ruleForm.isHasGuarantee" class="el-icon-refresh iconRight" @click="verifyData" />
          <p v-if="ruleForm.isHasGuarantee" class="tip">
            已选担保公司暂未成为平台会员，请添加
            <router-link :to="{path:'/memberManagement/addMember'}">
              前往添加
            </router-link>
          </p>
        </div>
      </el-form-item>
      <el-form-item
        v-if="ruleForm.needGuarantee===2&&ruleForm.source===1"
        :rules="[...rules.selectiveType]"
        label="担保协议模板"
        prop="guarCompcatId"
      >
        <el-select
          v-model="ruleForm.guarCompcatId"
          size="small"
          placeholder="请选择"
          @change="getSecurity"
        >
          <el-option
            v-for="(item,index) in securityTemplateArr"
            :key="index"
            :label="item.compactName"
            :value="item.compactId"
          />
        </el-select>
      </el-form-item>
      <!-- 融资所需合同 -->
      <h3 class="page-subtitle-shade titlePanel">
        融资所需合同
        <span class="addButton" @click="addItem(1)">+添加合同</span>
      </h3>
      <div class="overallWidth2">
        <Table :operation-button="operationButton1" :item-data="itemData1" :list-data="listData1">
          <template #compactType="{row,index}">
            <el-select
              v-model="row.compactType"
              size="small"
              placeholder="请选择"
              :disabled="index===0"
              @change="getTemplate(row,index)"
            >
              <el-option
                v-for="item in $store.getters.getDictionaryItem('compactType')"
                :key="item.dictId"
                :label="item.dictName"
                :value="item.dictId"
                :disabled="item.dictId==='GUARANTEE_AGREE'"
              />
            </el-select>
          </template>
          <template #compactId="{row}">
            <el-select v-model="row.compactId" size="small" placeholder="请选择" @change="getTemplateName(row)">
              <el-option
                v-for="(val,index) in row.templateArr||[]"
                :key="index"
                :label="val.compactName"
                :value="val.compactId"
              />
            </el-select>
          </template>
        </Table>
      </div>
      <!-- 费用配置 -->
      <h3 class="page-subtitle-shade titlePanel">
        费用配置
        <span class="addButton" @click="addItem(2)">+添加费用</span>
      </h3>
      <div class="overallWidth2">
        <Table :operation-button="operationButton2" :item-data="itemData2" :list-data="listData2">
          <template #costType="{row,index}">
            <el-select
              v-model="row.costType"
              size="small"
              placeholder="请选择"
              :disabled="ruleForm.source===1&&row.costType==='interest_rate'"
              @change="unrepeatable(row,index)"
            >
              <el-option
                v-for="item in $store.getters.getDictionaryItem('finance_fee_type')"
                :key="item.dictId"
                :disabled="ruleForm.source===2&&item.dictId==='interest_rate'"
                :label="item.dictName"
                :value="item.dictId"
              />
            </el-select>
          </template>
          <template #charging="{row}">
            <div class="flexBox">
              <el-input-number
                v-model="row.charging"
                :precision="2"
                :controls="false"
                placeholder="请输入"
                :min="0"
              />
              <el-select v-model="row.chargingRule" :disabled="ruleForm.source===2&&item.dictId==='interest_rate'" size="small" placeholder="请选择">
                <el-option label="%" :value="1" />
                <el-option label="%/年" :value="2" />
                <el-option label="元/笔" :value="3" />
              </el-select>
            </div>
          </template>
          <template #minCharging="{row}">
            <el-input v-model="row.minCharging" placeholder="请输入" />
          </template>
          <template #funderWithhold="{row}">
            <el-select v-model="row.funderWithhold" size="small" placeholder="请选择">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="2" />
            </el-select>
          </template>
          <template #receiver="{row}">
            <el-select v-model="row.receiver" size="small" placeholder="请选择">
              <el-option label="资金方" :value="1" />
              <el-option label="担保方" :value="2" />
              <el-option label="平台方" :value="3" />
            </el-select>
          </template>
          <template #payer="{row}">
            <el-select v-model="row.payer" size="small" placeholder="请选择">
              <el-option label="购买方" :value="1" />
              <el-option label="销售方" disabled :value="2" />
            </el-select>
          </template>
        </Table>
      </div>
      <!-- 授信所需材料 -->
      <h3 class="page-subtitle-shade titlePanel">
        授信所需材料
        <span class="addButton" @click="addItem(3)">+添加材料</span>
      </h3>
      <div class="overallWidth2">
        <Table :operation-button="operationButton3" :item-data="itemData3" :list-data="listData3">
          <template #documentCode="{row,index}">
            <el-select
              v-model="row.documentCode"
              size="small"
              placeholder="请选择"
              @change="changeCompact(row,index)"
            >
              <el-option
                v-for="item in $store.getters.getDictionaryItem('finance_document_type')"
                :key="item.dictId"
                :label="item.dictName"
                :value="item.dictId"
              />
            </el-select>
          </template>
          <template #documentTypeArr="{row}">
            <el-select
              v-model="row.documentTypeArr"
              size="small"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in $store.getters.getDictionaryItem('finance_document_suffix_type')"
                :key="item.dictId"
                :label="item.dictName"
                :value="item.dictName"
              />
            </el-select>
          </template>
          <template #necessary="{row}">
            <el-select v-model="row.necessary" size="small" placeholder="请选择">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="2" />
            </el-select>
          </template>
        </Table>
      </div>
      <!-- 备注 -->
      <el-form-item class="overallWidth remark" label="备注" prop="cmpShortName">
        <el-input
          v-model="ruleForm.cmpShortName"
          type="textarea"
          rows="2"
          size="small"
          maxlength="30"
          placeholder="请输入"
        />
      </el-form-item>
    </el-form>
    <div class="form-operation">
      <router-link :to="{ path: '/memberManagement/index' }">
        <el-button size="small" plain class="widen-button">
          取消
        </el-button>
      </router-link>
      <el-button
        size="small"
        :loading="loading"
        type="primary"
        class="widen-button"
        @click="submitForm('ruleForm')"
      >
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table'
import {
  compactServiceGetCompactByType,
  productFunderList,
  productAdd,
  productValidate,
  productDetails,
  productEdit,
  productValidateFunder,
  productGuaranteeList
} from '@/api/orderFinancing'
import { rules } from '@/utils/matchFormRegex'
export default {
  components: { Table },
  data() {
    const maxLoanCycle = (rule, value, callback) => {
      if (!this.ruleForm.maxLoanCycle) {
        callback(new Error('请填写整数'))
        return
      }
      if (!this.ruleForm.maxLoanCycleUnit) {
        callback(new Error('请选择单位'))
        return
      }
      callback()
    }
    return {
      operationButton1: [
        {
          bType: 'primary',
          icon: 'el-icon-circle-plus-outline',
          withParameters: 1,
          handleEvent: this.addItem
        },
        {
          bType: 'danger',
          icon: 'el-icon-remove-outline',
          withParameters: 1,
          handleEvent: this.deleteItem
        }
      ],
      itemData1: [
        { label: '合同类型', prop: 'compactType', required: true },
        { label: '合同模板', prop: 'compactId', required: true }
      ],
      listData1: [{ compactType: '07' }],
      operationButton2: [
        {
          bType: 'primary',
          icon: 'el-icon-circle-plus-outline',
          withParameters: 2,
          handleEvent: this.addItem
        },
        {
          bType: 'danger',
          icon: 'el-icon-remove-outline',
          withParameters: 2,
          handleEvent: this.deleteItem
        }
      ],
      itemData2: [
        { label: '费用类型', prop: 'costType', required: true, width: 200 },
        { label: '收费方式', prop: 'charging', required: true, width: 300 },
        { label: '单笔最低收取(元)', prop: 'minCharging', width: 200 },
        {
          label: '资方是否代扣',
          prop: 'funderWithhold',
          required: true,
          width: 200
        },
        { label: '收取方', prop: 'receiver', required: true, width: 200 },
        { label: '支付方', prop: 'payer', required: true, width: 200 }
      ],
      listData2: [{ costType: 'interest_rate', costName: '融资手续费', chargingRule: 2 }],
      operationButton3: [
        {
          bType: 'primary',
          icon: 'el-icon-circle-plus-outline',
          withParameters: 3,
          handleEvent: this.addItem
        },
        {
          bType: 'danger',
          icon: 'el-icon-remove-outline',
          withParameters: 3,
          handleEvent: this.deleteItem
        }
      ],
      itemData3: [
        { label: '材料名称', prop: 'documentCode' },
        { label: '材料类型', prop: 'documentTypeArr' },
        { label: '是否必传', prop: 'necessary' }
      ],
      listData3: [],
      ruleForm: {
        loanExtensionPeriodUnit: 1,
        needSecurityAgreement: 1,
        maxLoanCycleUnit: 1,
        isHasGuarantee: false,
        needGuarantee: 2,
        source: 1
      },
      funderArr: [],
      guaranteeArr: [],
      loading: false,
      securityTemplateArr: [],
      rules: {
        ...rules,
        maxLoanCycle: [
          {
            required: true,
            validator: maxLoanCycle,
            trigger: ['input', 'blur', 'change']
          }
        ]
      }
    }
  },
  watch: {
    '$route.query.id': {
      handler(newVal) {
        if (newVal) this.getProductDetail(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.remoteGuarantee()
    this.remoteFunder()
    compactServiceGetCompactByType(['GUARANTEE_AGREE'], res => {
      this.securityTemplateArr = [...res.data]
    })
    // 第一个参数的要根据已选的进行去渲染
    if (!this.$route.query.id) this.getTemplate(this.listData1[0], 0)
  },
  methods: {
    // 改变有无担保
    changeNeedGuarantee(e) {
      if (e === 1) {
        this.ruleForm.needSecurityAgreement = 2
      }
    },
    // 获取详情
    getProductDetail(id) {
      productDetails(id, res => {
        const data = res.data
        if (data.agreementList) this.listData1 = [...data.agreementList]
        if (data.costRuleList) this.listData2 = [...data.costRuleList]
        if (data.documentList) {
          data.documentList.forEach(item => {
            item.documentTypeArr = item.documentType.split(',') || []
          })
        }
        if (data.documentList) this.listData3 = [...data.documentList]
        this.listData1.forEach((item, index) => {
          this.getTemplate(item, index, true)
        })
        this.ruleForm = { ...data }
        if (this.ruleForm.guaranteeList && this.ruleForm.guaranteeList.length > 0) {
          this.ruleForm.guaranteeCmpUnicode = this.ruleForm.guaranteeList[0].guaranteeCmpUnicode
          this.ruleForm.guaranteeId = this.ruleForm.guaranteeList[0].guaranteeId
          this.ruleForm.guaranteeName = this.ruleForm.guaranteeList[0].guaranteeName
          this.ruleForm.guaranteeType = this.ruleForm.guaranteeList[0].guaranteeType
        }
      })
    },
    // 获取担保协议模板名称
    getSecurity(e) {
      this.securityTemplateArr.forEach(item => {
        if (item.compactId === e) {
          this.ruleForm.guarCompcatName = item.compactName
        }
      })
    },
    // 通过ID拿取合同模板名
    getTemplateName(row) {
      if (row && row.templateArr) {
        row.templateArr.forEach(item => {
          if (row.compactId === item.compactId) {
            this.$set(row, 'compactTemplateName', item.compactName)
          }
        })
        return
      }
      this.$set(row, 'compactTemplateName', '')
    },
    // 改变担保方的时候
    changeGuarantee(e) {
      this.guaranteeArr.forEach(item => {
        if (item.id === e) {
          this.ruleForm.guaranteeName = item.guaranteeCmpName
          this.ruleForm.guaranteeCmpUnicode = item.guaranteeCmpUnicode
        }
      })
      this.verifyData()
    },
    // 效验担保公司是否在会员中心
    verifyData() {
      this.$forceUpdate()
      if (
        this.ruleForm.guaranteeId &&
        this.ruleForm.needSecurityAgreement === 1
      ) {
        productValidate(
          this.ruleForm.guaranteeId,
          () => {
            this.ruleForm.isHasGuarantee = false
          },
          () => {
            this.ruleForm.isHasGuarantee = true
          }
        )
        return
      }
      this.ruleForm.isHasGuarantee = false
    },
    // 当改变资金方的时候需要做的一些事情
    changeFunder(e) {
      this.funderArr.forEach(item => {
        if (item.id === e) {
          this.ruleForm.funderName = item.funderName
          this.ruleForm.funderUnicode = item.funderUnicode
        }
      })
      // 当选择的时候效验是否有产品  有的话不能选
      productValidateFunder(
        e,
        () => {},
        () => {
          this.ruleForm.funderId = ''
          this.$alert(
            '您选择的资金方已存在生效中的产品，如需重新配置，请先停用已有产品',
            '温馨提示',
            {
              confirmButtonText: '我知道了',
              center: true,
              callback: action => {}
            }
          )
        }
      )
    },
    // 当是资方确认的时候  费用类型为融资手续费要清除
    changeSource(e) {
      this.ruleForm.needGuarantee = 1
      this.ruleForm.needSecurityAgreement = 2
      let flag = true
      this.listData2.forEach((item, index) => {
        if (item.costType === 'interest_rate') {
          if (e === 2) this.$set(item, 'costType', '')
          if (e === 1) flag = false
        }
      })
      if (flag && e === 1) this.listData2.push({ costType: 'interest_rate', costName: '融资手续费', chargingRule: 2 })
      this.$forceUpdate()
    },
    // 添加列表的项
    addItem(row, index) {
      const num = row.constructor === Object ? index : row
      this[`listData${num}`].push({})
    },
    // 删除列表的项
    deleteItem(row, index, num) {
      if (index === 1 && num === 0) {
        this.$message.error('此项不能删除')
        return
      }
      if (row.costType === 'interest_rate' && this.ruleForm.source === 1) {
        this.$message.error('在后台录入下，融资手续费为必填项')
        return
      }
      this[`listData${index}`].splice(num, 1)
    },
    // 担保公司联动搜索
    remoteGuarantee(value) {
      productGuaranteeList(value, res => {
        this.guaranteeArr = [...res.data]
      })
    },
    // 资金方联动搜索
    remoteFunder(value) {
      productFunderList(value, res => {
        this.funderArr = [...res.data]
      })
    },
    // 获取合作协议模板
    getTemplate(row, index, type) {
      if (!row.compactType) {
        this.$message.error('请选择合同类型')
        return
      }
      let flag = false
      if (this.listData1.length > 1) {
        this.listData1.forEach((item, num) => {
          if (index !== num && item.compactType === row.compactType) flag = true
        })
      }
      if (flag) {
        this.$message.error('不能选择相同的合同类型')
        this.$set(row, 'compactType', '')
        this.$set(row, 'compactId', '')
        return
      }
      compactServiceGetCompactByType([row.compactType], res => {
        this.$set(row, 'templateArr', [...res.data])
        if (!type) this.$set(row, 'compactId', '')
      })
      // 给名称赋值
      const arr = this.$store.getters.getDictionaryItem('compactType')
      arr.forEach(item => {
        if (item.dictId === row.compactType) { this.$set(row, 'compactName', item.dictName) }
      })
    },
    // 所选费用不能相同
    unrepeatable(row, index) {
      let flag = false
      if (this.listData2.length > 1) {
        this.listData2.forEach((item, num) => {
          if (index !== num && item.costType === row.costType) flag = true
        })
      }
      if (flag) {
        this.$message.error('不能选择相同的费用类型')
        this.$set(this.listData2, index, {})
        return
      }
      // 给名字赋值
      const arr = this.$store.getters.getDictionaryItem('finance_fee_type')
      arr.forEach(item => {
        if (item.dictId === row.costType) { this.$set(row, 'costName', item.dictName) }
      })
    },
    // 材料名称不能选择一样的
    changeCompact(row, index) {
      let flag = false
      if (this.listData3.length > 1) {
        this.listData3.every((item, num) => {
          if (index !== num && item.documentCode === row.documentCode) flag = true
        })
      }
      if (flag) {
        this.$message.error('不能选择相同的材料名称')
        this.$set(this.listData3, index, {})
        return
      }
      // 给名字赋值
      const arr = this.$store.getters.getDictionaryItem(
        'finance_document_type'
      )
      arr.forEach(item => {
        if (item.dictId === row.documentCode) { this.$set(row, 'documentName', item.dictName) }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 验证表格的必填项  是否填完
          let flag1 = false
          this.listData1.forEach(item => {
            if (!item.compactId || !item.compactType) flag1 = true
          })
          if (flag1) {
            this.$message.error('请填写完融资所需合同的必填项')
            return
          }
          let flag2 = false
          this.listData2.forEach(item => {
            if (
              !item.charging ||
              !item.costType ||
              !item.receiver ||
              !item.chargingRule ||
              !item.funderWithhold ||
              !item.payer
            ) { flag2 = true }
          })
          if (flag2) {
            this.$message.error('请填写完费用配置的必填项')
            return
          }
          let flag3 = false
          this.listData3.forEach(item => {
            if (
              item.documentTypeArr.length === 0 ||
              !item.documentCode ||
              !item.necessary
            ) { flag3 = true }
            item.documentType = item.documentTypeArr.join('/')
          })
          if (flag3) {
            this.$message.error('请填写完授信所需材料的全部项')
            return
          }
          this.ruleForm.agreementList = [...this.listData1]
          this.ruleForm.costRuleList = [...this.listData2]
          this.ruleForm.documentList = [...this.listData3]
          this.ruleForm.guaranteeList = [
            {
              guaranteeCmpUnicode: this.ruleForm.guaranteeCmpUnicode || '',
              guaranteeId: this.ruleForm.guaranteeId || '',
              guaranteeName: this.ruleForm.guaranteeName || '',
              guaranteeType: this.ruleForm.guaranteeType || ''
            }
          ]
          this.ruleForm.agreementList.forEach(item => {
            item.templateArr = null
          })
          this.loading = true
          // 判断是新增还是修改
          const api = this.ruleForm.id ? productEdit : productAdd
          api(
            this.ruleForm,
            () => {
              this.$message.success('成功！')
              this.loading = false
              this.$refs.ruleForm.resetFields()
              // 成功之后   把数据清除
              this.listData1 = []
              this.listData2 = []
              this.listData3 = []
              this.ruleForm = {
                loanExtensionPeriodUnit: 1,
                needSecurityAgreement: 1,
                maxLoanCycleUnit: 1,
                needGuarantee: 2,
                source: 1
              }
              this.$router.push({ path: '/orderFinancing/productManagement' })
            },
            () => {
              this.loading = false
            }
          )
        }
      })
    }
  }
}
</script>

<style lang="scss">
.addProductPageBox {
  .el-form-item {
    margin-left: 22px;
  }
  .allWidth {
    width: calc(100% - 120px);
    .el-form-item__content {
      width: 100%;
    }
  }
  .flexBox {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    .tip {
      color: #333;
      font-size: 14px;
      margin-left: 12px;
      a {
        color: #0089ff;
        margin-left: 12px;
      }
    }
    .iconLeft {
      font-size: 20px;
      margin-right: 12px;
    }
    .iconRight {
      font-size: 20px;
      margin-left: 12px;
      cursor: pointer;
    }
    .positionBox {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      line-height: 1;
    }
    .interval {
      margin-right: 32px;
    }
    .interval2 {
      margin-left: 32px;
    }
  }
  .el-radio {
    margin-right: 18px;
  }
  .el-radio:last-of-type {
    margin-right: 0;
  }
  .overallWidth {
    width: 100% !important;

    .tip {
      color: #93959a;
      font-size: 12px;
    }
  }
  .page-subtitle-shade {
    margin-bottom: 32px;
  }
  .overallWidth2 {
    width: calc(100% - 22px);
    margin: 0 0 32px 22px;
  }
  .remark .el-form-item__content {
    width: calc(100% - 120px);
  }
  .titlePanel {
    .addButton {
      margin-left: 12px;
      color: #0089ff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
